html,
body {
  box-sizing: border-box;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Roboto Flex', 'Lato', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noselect {
  user-select: none;
}

.pointer-events-none {
  pointer-events: none;
}
